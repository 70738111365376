* {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0;
  /* transform: translate() */
}

:root {
  --x: 0px;
  --y: 0px;
  --rotate: 0deg;
  --scale: 0;
  --transition: 0s;
  --imageX: 0;
  --imageY: 0;
}

body {
  max-width: 100vw;
  /* overflow-x: hidden; */
  margin: 0;
  /* cursor: none; */
}
